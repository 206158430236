import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './css/Home.css';

const Home = () => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userData, setUserData] = useState(null);
  const [showUserId, setShowUserId] = useState(false);

  useEffect(() => {
    const storedUserId = localStorage.getItem('user_id');
    setUserId(storedUserId);

    let isMounted = true; // Add a variable to track component mount/unmount

    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/user/${storedUserId}`
        );

        // Check if the component is still mounted before updating state
        if (isMounted) {
          setUserData(response.data);
        }
      } catch (error) {
        console.error('Could not fetch user data', error);
      }
    };

    fetchUserData();

    // Cleanup function to cancel the asynchronous task
    return () => {
      isMounted = false; // Set isMounted to false to indicate unmount
    };
  }, []);

    const toggleMenu = () => setShowMenu(!showMenu);

    const contentContainerStyle = {
        marginLeft: showMenu ? '250px' : '0px',
    };

    const handleGoHome = () => {
        navigate('/home');
    };

    const handleGoStore = () => {
        navigate('/store');
    };

    const handleLogout = () => {
        localStorage.removeItem('user_id');
        navigate('/');
    };

    const handleMouseDown = () => {
        setShowUserId(true);
    };

    const handleMouseUp = () => {
        setShowUserId(false);
    };

    return (
        <div className="Home-home-container">
            <button className="Home-toggle-button" onClick={toggleMenu}>
                <img
                    src="/hamMenu2.jpg"
                    alt="Toggle Menu"
                    style={{ width: 'auto', height: '100%' }}
                />
            </button>
            <div className={showMenu ? "Home-side-menu active" : "Home-side-menu"}>
                <div className="Home-menu-content">
                    <ul>
                        <li onClick={handleGoHome}>Home</li>
                        <li onClick={handleGoStore}>Store</li>
                    </ul>
                </div>
                <button className="Home-purple-logout-button" onClick={handleLogout}>
                    Logout
                </button>
            </div>
            <div className="Home-content-container" style={contentContainerStyle}>
                <div className="Home-logo-placeholder">
                    <img src="/vortex_logo_black.png" alt="Vortex Logo" />
                </div>
                <div className="Home-main-content">
                    <h1>Welcome to the Home Page!</h1>
                    {userData && (
                        <table className="Home-profile-table">
                            <tbody>
                                <tr>
                                    <th className="Home-table-header">Name</th>
                                    <td className="Home-table-data" colSpan="2">{userData.username}</td>
                                </tr>
                                <tr>
                                    <th className="Home-table-header">Email</th>
                                    <td className="Home-table-data" colSpan="2">{userData.email}</td>
                                </tr>
                                <tr>
                                    <th className="Home-table-header">Your User ID is:</th>
                                    <td className="Home-table-data">
                                        {showUserId ? userId : userId ? '*'.repeat(userId.length) : ''}
                                    </td>
                                    <td>
                                        <button onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseLeave={handleMouseUp}>
                                            <img
                                                src="/hideEye.png"
                                                alt="Show User ID"
                                                style={{ width: '20px', height: '20px', verticalAlign: 'middle' }}
                                            />
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Home;