import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import './css/Login.css';

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);

    const isValidEmail = (email) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(email);
    };

    const handleLogin = async () => {
        if (!isValidEmail(email)) {
            setErrorMessage('Invalid email address.');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/login`, { email, password });
            console.log(response.data);
            setErrorMessage(null);

            if (response.status === 200 && response.data) {
                const { _id, role, token, isActive } = response.data;

                // Store user _id, role, and JWT token in localStorage
                localStorage.setItem('user_id', _id);
                localStorage.setItem('role', role);
                localStorage.setItem('token', token);

                if (role === 'admin') {
                    console.log('Admin role detected. Updating localStorage and Redirecting to /adminhome');
                    navigate('/adminhome');
                    return;
                }

                if (!isActive) {
                    setErrorMessage('Account is not active. Please proceed to verification.');
                    return;
                }

                if (role === 'dealer') {
                    console.log('Dealer role detected. Updating localStorage and Redirecting to /home');
                    navigate('/home');
                } else {
                    setErrorMessage('Invalid role.');
                }
            } else {
                setErrorMessage('Invalid email/password. Try again.');
            }
        } catch (error) {
            console.error(error);
            setErrorMessage('An error occurred. Please try again.');
        }
    };

    return (
        <div className="page">
            <div className="content">
                <div className="logo-and-login-container">
                    <div className="logo-container">
                        <img src="/vortex_logo_black.png" alt="Vortex Logo" />
                    </div>

                    <div className="login-container">
                        <div className="login-section">
                            <h1>Login</h1>
                            <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />

                            <div className="button-container">
                                <button onClick={handleLogin}>Login</button>
                                <Link to="/signup">
                                    <button>Signup</button>
                                </Link>
                            </div>

                            {errorMessage && <p className="error-message">{errorMessage}</p>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
