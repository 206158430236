import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Verify = () => {
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    const verifyEmail = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/verify`, {
                email,
                code,
            });
    
            if (response.status === 200) {
                await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/updateUserStatus`, {
                    email, 
                });

                setSuccessMessage('Successful Verification. Proceeding to login page...');
                setTimeout(() => {
                    navigate('/');
                }, 3000);
    
            } else {
                setErrorMessage('Invalid email or verification code.');
            }
        } catch (error) {
            console.error('Verification error:', error);
            if (error.response && error.response.status === 400) {
                setErrorMessage('Invalid email or verification code.');
            } else {
                setErrorMessage('An error occurred during verification.');
            }
        }
    };

    return (
        <div>
            <h2>Email Verification</h2>
            <div>
                <label>Email:</label>
                <input
                    type="text"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div>
                <label>Verification Code:</label>
                <input
                    type="text"
                    placeholder="Enter the verification code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                />
            </div>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
            <button onClick={verifyEmail}>Verify</button>
        </div>
    );
};

export default Verify;
