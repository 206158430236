import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './css/AdminShopApproval.css';

const AdminShopApproval = () => {
    const navigate = useNavigate();
    const [storesNeedingApproval, setStoresNeedingApproval] = useState([]);
    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        const role = localStorage.getItem('role');
        if (role !== 'admin') {
            navigate('/');
        }

        const fetchStoresNeedingApproval = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/storepending`,
                    {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    }
                );
                console.log('API Response:', response.data);
                if (Array.isArray(response.data)) {
                    setStoresNeedingApproval(response.data);
                } else if (response.data && Array.isArray(response.data.stores)) {
                    setStoresNeedingApproval(response.data.stores);
                } else {
                    console.error('Unexpected API response format');
                }
            } catch (error) {
                console.error('Could not fetch stores needing approval', error);
            }
        };

        fetchStoresNeedingApproval();
    }, [navigate]);

    const handleApprove = async (storeId) => {
        try {
            const userRole = localStorage.getItem('role');
            console.log(`User Role: ${userRole}`);
            console.log(`Token: ${localStorage.getItem('token')}`);

            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_URL}/api/stores/approve/${storeId}`,
                {},
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );
            console.log('Response:', response);

            if (response.status === 200) {
                setStoresNeedingApproval(prevStores =>
                    prevStores.map(store => {
                        if (store._id === storeId) {
                            return {
                                ...store,
                                needsApproval: false,
                                isApproved: true
                            };
                        }
                        return store;
                    })
                );

                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        } catch (error) {
            console.error('Could not approve store', error);
        }
    };

    const handleGoHome = () => {
        navigate('/adminhome');
    };

    const handleGoShopApproval = () => {
        navigate('/adminhome/shopapproval');
    };

    const handleLogout = () => {
        localStorage.removeItem('user_id');
        navigate('/');
    };

    const toggleMenu = () => setShowMenu(!showMenu);

    const contentContainerStyle = {
        marginLeft: showMenu ? '250px' : '0px',
    };

    return (
        <div className="AdminShopApproval-home-container">
            <button className="AdminHome-toggle-button" onClick={toggleMenu}>
                <img
                    src="/hamMenu2.jpg"
                    alt="Toggle Menu"
                    style={{ width: 'auto', height: '100%' }}
                />
            </button>
            <div className={showMenu ? "AdminShopApproval-side-menu active" : "AdminShopApproval-side-menu"}>
                <div className="AdminShopApproval-menu-content">
                    <ul>
                        <li onClick={handleGoHome}>Home</li>
                        <li onClick={handleGoShopApproval}>Shop Approval</li>
                    </ul>
                </div>
                <button className="AdminShopApproval-purple-logout-button" onClick={handleLogout}>
                    Logout
                </button>
            </div>

            <div className="AdminShopApproval-main-content" style={contentContainerStyle}>
                <div className="AdminShopApproval-logo-placeholder">
                    <img src="/vortex_logo_black.png" alt="Vortex Logo" />
                </div>
                <h1>Admin: Shop Approval</h1>
                <table className="AdminShopApproval-profile-table">
                    <thead>
                        <tr>
                            <th>Store Name</th>
                            <th>Store Logo</th>
                            <th>Store URL</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {storesNeedingApproval.map(store => (
                            <tr key={store._id}>
                                <td>{store.storeName}</td>
                                <td>
                                    <img
                                        src={`${process.env.REACT_APP_BACKEND_URL}/public/img/${store.storeLogo}`}
                                        alt="Store Logo"
                                        style={{ maxWidth: '100px' }}
                                    />
                                </td>
                                <td>{store.storeUrl}</td>
                                <td>
                                    <button onClick={() => handleApprove(store._id)}>Approve</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AdminShopApproval;
