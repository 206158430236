import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './css/SignUp.css';

const Signup = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('dealer');
    const [emailExists, setEmailExists] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleSignup = async () => {
        try {
            setEmailExists(false);
            setErrorMessage('');
            console.log('Signing up user...');
    
            const signupData = { username, email, password, role };
            console.log('Signup data:', signupData);
    
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/signup`, signupData);
            console.log('Signup response:', response.data);
    
            // Create default store page linked to the user
            if (response.data._id && role !== 'admin') {
                console.log('Creating default store...');
                const storeData = {
                    ownerId: response.data._id,
                    storeName: email.split('@')[0],
                    storeLogo: 'vortex_logo_black.png',
                    needsApproval: false,
                    isApproved: false,
                    isLive: false
                };
                console.log('Store data:', storeData);
    
                try {
                    const storeResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/createstore`, storeData);
                    console.log('Store creation response:', storeResponse.data);
                } catch (storeError) {
                    console.error('Store creation error:', storeError);
                    setErrorMessage('Error creating default store.');
                }
            }
    
            setShowSuccessMessage(true);
            navigate('/verify'); // Redirect to the verify page
    
        } catch (error) {
            console.error("Signup error:", error);
    
            if (error.response && error.response.data) {
                console.log("Server response:", error.response.data);
                setErrorMessage(error.response.data.message);
            } else {
                console.error("Other error:", error);
                setErrorMessage('An error occurred during signup.');
            }
        }
    };    

    useEffect(() => {
        if (showSuccessMessage) {
            const redirectTimer = setTimeout(() => {
                navigate('/');
            }, 5000);
            return () => clearTimeout(redirectTimer);
        }
    }, [showSuccessMessage, navigate]);

    return (
        <div className="signup-container">
            <img src="/vortex_logo_black.png" alt="Vortex Logo" className="logo" onClick={() => navigate('/')} />
            <h1>Signup</h1>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            <div className="form-section">
                <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
            </div>
            <div className="form-section">
                <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className="form-section">
                <div className="password-input-container">
                    <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button className="password-toggle-button" onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? "Hide" : "Show"}
                    </button>
                </div>
            </div>
            <div className="form-section">
                <select value={role} onChange={(e) => setRole(e.target.value)}>
                    <option value="dealer">Dealer</option>
                    <option value="admin">Admin</option>
                </select>
            </div>
            <button onClick={handleSignup}>Signup</button>
            {emailExists && <p style={{ color: 'red' }}>User already exists</p>}
            {showSuccessMessage && (
                <div className="popup">
                    <p>Successful Sign-Up!</p>
                    <p>Redirecting to Login...</p>
                </div>
            )}
        </div>
    );
};

export default Signup;
