import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useStore } from './StoreContext';

const SubdomainHandler = () => {
  const navigate = useNavigate();
  const { setStoreData, setHasSubdomain } = useStore();  // get the setStoreData function from the context

  useEffect(() => {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');

    // Check for the main domain or specific subdomains and handle them differently
    if (
      hostname === 'vaas.sparkles.com.ph' ||
      hostname === 'vaasstorepagefront.pages.dev' ||
      parts[0] === 'www' ||
      parts[0] === 'vaas'
    ) {
      setHasSubdomain(false);
      return;
    }

    if (parts.length > 2) {
      const subdomain = parts[0];

      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/api/stores/url/${subdomain}/live`)
        .then((response) => {
          if (response.data) {
            setStoreData({ subdomain, ...response.data });
            setHasSubdomain(true);
          } else {
            // Redirect to a not found page or handle as needed
            navigate(`/not-found`);
          }
        })
        .catch((error) => {
          console.error('Could not fetch live store', error);
          // Redirect to an error page or handle as needed
          navigate(`/error`);
        });
    } else {
      setHasSubdomain(false);
    }
  }, [navigate, setStoreData, setHasSubdomain]);

  // Return null or any loading indicator if needed
  return null;
};

export default SubdomainHandler;
