import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './css/Store.css';

const Store = () => {
    const navigate = useNavigate();
    const [showMenu, setShowMenu] = useState(false);
    const [storeData, setStoreData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedData, setEditedData] = useState({});
    const [isChanged, setIsChanged] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [progressText, setProgressText] = useState("Uploading...");


    useEffect(() => {
        console.log("storeData:", storeData);
    }, [storeData]);

    useEffect(() => {
        const storedUserId = localStorage.getItem('user_id');

        const fetchStoreData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/stores/owner/${storedUserId}`);
                setStoreData(response.data);
                setEditedData(response.data);
            } catch (error) {
                console.error('Could not fetch store data', error);
            }
        };

        fetchStoreData();

        return () => {
            if (editedData.storeLogo) {
                URL.revokeObjectURL(editedData.storeLogo);
            }
        };
    }, []);
    const toggleMenu = () => setShowMenu(!showMenu);

    const contentContainerStyle = {
        marginLeft: showMenu ? '250px' : '0px',
    };

    const handleGoHome = () => {
        navigate('/home');
    };

    const handleGoStore = () => {
        navigate('/store');
    };

    const handleLogout = () => {
        localStorage.removeItem('user_id');
        navigate('/');
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const isValidSubdomain = (subdomain) => {
        const regex = /^[a-z\d][a-z\d-]*[a-z\d]$/i;
        return regex.test(subdomain);
    };

    const handleSaveClick = async () => {
        console.log("Save button clicked");
        try {
            const storedUserId = localStorage.getItem('user_id');

            if (!isValidSubdomain(editedData.storeUrl)) {
                alert("Invalid subdomain");
                return;
            }

            const updatedData = {
                ...editedData,
                storeSubdomain: editedData.storeUrl,
                needsApproval: true,
                isLive: false,
                isApproved: false,
            };

            delete updatedData.storeLogo;

            const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/stores/owner/update/${storedUserId}`, updatedData);

            if (response.status === 200) {
                setStoreData(prevStoreData => ({
                    ...prevStoreData,
                    isApproved: false,
                    needsApproval: true,
                    isLive: false,
                }));
                setIsEditing(false);
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            } else {
                console.error('Could not update store data, received status: ', response.status);
            }
        } catch (error) {
            console.error('Could not update store data', error);
        }
    };

    const handleInputChange = (e, field) => {
        setEditedData({
            ...editedData,
            [field]: e.target.value
        });
    };

    const handlePreviewClick = () => {
        navigate(`/preview/${editedData.storeUrl}`);
    };

    const handleFileUpload = (event) => {
        console.log('File selected:', event.target.files[0]);
        const file = event.target.files[0];

        // File type and size validation
        if (file && ['image/jpeg', 'image/png'].includes(file.type)) {
            if (file.size <= 5000000) { // file size <= 5MB
                const storeName = editedData.storeName.replace(/\s+/g, '_');
                const fileName = `${storeName}_logo.${file.type.split('/')[1]}`;

                setEditedData({
                    ...editedData,
                    storeLogo: file,
                    logoFileName: fileName,
                });
            } else {
                alert('File size must not exceed 5MB');
            }
        } else {
            alert('Supported file types are JPEG and PNG');
        }
    };

    const handleUploadClick = async () => {
        console.log('Upload button clicked');
        console.log('Before upload:', editedData);

        if (editedData.storeLogo) {
            setUploading(true);
            setProgress(0);

            // Simulate progress bar filling up in 5 seconds
            let interval = setInterval(() => {
                setProgress(oldProgress => {
                    if (oldProgress >= 100) {
                        clearInterval(interval);
                        setProgressText("Successfully Uploaded!");
                        setUploading(false);
                        return 100;
                    }
                    return Math.min(oldProgress + 10, 100);
                });
            }, 500);

            const formData = new FormData();
            const storedUserId = localStorage.getItem('user_id');
            formData.append('ownerId', storedUserId);
            formData.append('storeLogo', editedData.storeLogo);
            formData.append('logoFileName', editedData.logoFileName);
            formData.append('storeName', editedData.storeName);

            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                }
            };

            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/upload`, formData, config);

                if (response.data.url) {
                    const newLogoFileName = response.data.url.split('/').pop();
                    setEditedData({
                        ...editedData,
                        storeLogo: response.data.url,
                        logoFileName: newLogoFileName,
                    });
                    setProgressText("Successfully Uploaded!");
                }

                clearInterval(interval);
                setUploading(false);
                setProgress(100);

            } catch (error) {
                console.error('Error uploading logo:', error);
                clearInterval(interval);
                setUploading(false);
                setProgress(0);
            }
        } else {
            console.error('No file to upload.');
        }
    };


    const handleGoLiveClick = async () => {
        try {
            const storedUserId = localStorage.getItem('user_id');  // Fetch the user ID from local storage

            // Fetch the latest store data to get the current isApproved status
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/stores/owner/${storedUserId}`);
            const updatedStoreData = response.data;

            console.log("Before update - isApproved:", updatedStoreData.isApproved);
            console.log("Before update - isLive:", updatedStoreData.isLive);

            // Use the storedUserId when making the PUT request
            const updateResponse = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/stores/owner/update/${storedUserId}`, {
                isLive: true,
            }, {
                headers: {
                    'Owner-Id': storedUserId  // Add a custom header to pass the owner ID
                }
            });

            if (updateResponse.status === 200) {
                setStoreData(prevStoreData => ({
                    ...prevStoreData,
                    isLive: true,
                }));
            } else {
                console.error('Could not update isLive status, received status: ', updateResponse.status);
            }
        } catch (error) {
            console.error('Could not update isLive status', error);
        }
    };



    const previewLogoUrl = `${process.env.REACT_APP_BACKEND_URL}/public/img/${editedData.storeLogo}`;

    const formData = new FormData();
    const storedUserId = localStorage.getItem('user_id');
    formData.append('ownerId', storedUserId);
    formData.append('storeName', editedData.storeName);
    formData.append('storeLogo', editedData.storeLogo);


    return (
        <div className="store-container">
            <button className="store-toggle-button" onClick={toggleMenu}>
                <img
                    src="/hamMenu2.jpg"
                    alt="Toggle Menu"
                    style={{ width: 'auto', height: '100%' }}
                />
            </button>
            <div className={showMenu ? "store-side-menu active" : "Home-side-menu"}>
                <div className="store-menu-content">
                    <ul>
                        <li onClick={handleGoHome}>Home</li>
                        <li onClick={handleGoStore}>Store</li>
                    </ul>
                </div>
                <button className="store-purple-logout-button" onClick={handleLogout}>
                    Logout
                </button>
            </div>
            <div className="store-content-container" style={contentContainerStyle}>
                <div className="store-logo-placeholder">
                    {storeData && storeData.storeLogo ? (
                        <img
                            src={`${process.env.REACT_APP_BACKEND_URL}/public/img/${storeData.storeLogo}`}
                            alt="Your Store Logo"
                            style={{ maxWidth: '400px', maxHeight: '400px' }}
                        />
                    ) : (
                        <img
                            src="/vortex_logo_black.png"
                            alt="Default Logo"
                            style={{ maxWidth: '400px', maxHeight: '400px' }}
                        />
                    )}
                </div>

                <div className="store-main-content">
                    <h1>Welcome to the Store Page!</h1>
                    {storeData && (
                        <table className="store-profile-table">
                            <tbody>
                                <tr>
                                    <th className="store-table-header first-column">Store Name</th>
                                    <td className="store-table-data" colSpan="2">
                                        {isEditing ? (
                                            <input
                                                type="text"
                                                value={editedData.storeName}
                                                onChange={(e) => handleInputChange(e, 'storeName')}
                                            />
                                        ) : (
                                            storeData.storeName
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="store-table-header">Store Logo</th>
                                    <td className="store-table-data">
                                        {isEditing ? (
                                            <>
                                                <input type="file" accept="image/png, image/jpeg" onChange={handleFileUpload} />
                                                <img
                                                    src={previewLogoUrl}
                                                    alt="Your Store Logo"
                                                    style={{ maxHeight: '200px', maxWidth: '100%' }}
                                                />
                                                {editedData.logoFileName && <p>{editedData.logoFileName}</p>} {/* Display new filename */}
                                            </>
                                        ) : (
                                            storeData.storeLogo
                                        )}
                                    </td>
                                    <td>
                                        {isEditing && <button onClick={handleUploadClick}>Upload</button>}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="store-table-header">Store URL</th>
                                    <td className="store-table-data" colSpan="2">
                                        {isEditing ? (
                                            <input
                                                type="text"
                                                value={editedData.storeUrl}
                                                onChange={(e) => handleInputChange(e, 'storeUrl')}
                                            />
                                        ) : (
                                            storeData.storeUrl
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="store-table-header">Store Edit Status</th>
                                    <td className="store-table-data" colSpan="2">
                                        {storeData.needsApproval ? 'Needs Approval' : 'Approved'}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    )}

                    {isEditing ? (
                        <div>
                            <button onClick={handleSaveClick}>Save</button>
                            <br />
                            <br />
                            <button onClick={handlePreviewClick}>Preview</button>
                        </div>
                    ) : (
                        <div>
                            <button onClick={handleEditClick}>Edit</button>
                            <br />
                            <br />
                            <button onClick={handlePreviewClick}>Preview</button>
                            <br />
                            <br />
                            {storeData !== null && storeData.isApproved && !storeData.isLive && (
                                <button onClick={handleGoLiveClick}>Go Live</button>
                            )}
                        </div>
                    )}
                    {uploading && (
                        <div style={{
                            position: 'fixed',
                            bottom: '0',
                            left: '0',
                            width: '100%',
                            backgroundColor: '#333',
                            color: '#fff',
                            textAlign: 'center',
                            padding: '1em'
                        }}>
                            <div>{progressText}</div>
                            <progress max="100" value={progress}></progress>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Store;
