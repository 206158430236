import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';

const StorePage = () => {
    const [storeData, setStoreData] = useState(null);
    const { storeUrl } = useParams();

    useEffect(() => {
        const fetchStoreData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/stores/url/${storeUrl}`);
                setStoreData(response.data);

                console.log("Backend URL: ", process.env.REACT_APP_BACKEND_URL);
                console.log("Logo filename from MongoDB: ", response.data.storeLogo);
                console.log("Full URL: ", `${process.env.REACT_APP_BACKEND_URL}/public/img/${response.data.storeLogo}`);
            } catch (error) {
                console.error('Could not fetch store data', error);
            }
        };

        fetchStoreData();
    }, [storeUrl]);

    if (!storeData) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <div style={{ position: 'absolute', top: '0', left: '0', padding: '10px' }}>
                <Link to="/store">
                    <button>Back to Dashboard</button>
                </Link>
            </div>
            <div style={{ textAlign: 'center' }}>
                <img
                    src={`${process.env.REACT_APP_BACKEND_URL}/public/img/${storeData.storeLogo}`}
                    alt={`${storeData.storeName}'s Logo`}
                    style={{ maxWidth: '400px', maxHeight: '400px' }}
                />
                <h1>{storeData.storeName}</h1>
            </div>
        </div>
    );
};

export default StorePage;
