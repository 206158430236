import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { StoreProvider } from './StoreContext';
import Login from './Login';
import Signup from './Signup';
import Home from './Home';
import Store from './Store';
import StorePage from './StorePage';
import LiveStorePage from './LiveStorePage';
import AdminHome from './AdminHome';
import AdminShopApproval from './AdminShopApproval';
import SubdomainHandler from './SubdomainHandler';
import Verify from './Verify';

function App() {
  useEffect(() => {
    document.title = 'Vortex Store Page Prototype';
  }, []);

  // Function to check if a subdomain is present
  const hasSubdomain = () => {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');
  
    // Check if the hostname has more than two parts and does not equal the main domain
    return parts.length > 2 && parts[0] !== 'vaas';
  };

  return (
    <StoreProvider>
      <Router>
        <SubdomainHandler />
        <Routes>
          {hasSubdomain() ? (
            <Route path="/*" element={<LiveStorePage />} />
          ) : (
            <>
              <Route path="/" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/home" element={<Home />} />
              <Route path="/store" element={<Store />} />
              <Route path="/preview/:storeUrl" element={<StorePage />} />
              <Route path="/adminhome" element={<AdminHome />} />
              <Route path="/adminhome/shopapproval" element={<AdminShopApproval />} />
              <Route path="/:storeUrl" element={<LiveStorePage />} />
              <Route path="/verify" element={<Verify />} />
            </>
          )}
        </Routes>
      </Router>
    </StoreProvider>
  );
}

export default App;